<template>
  <app-dialog
    :loading="loading"
    :value="value"
    :query-param="queryParam"
    v-on="$listeners"
    @submit="onSubmit"
  >
    <template v-slot:title>
      <span class="subtitle-1 font-weight-bold">{{ $t('changeArticle.subline') }}</span>
      <h2>{{ $t('changeArticle.headline') }}</h2>
    </template>

    <p v-if="selectableProducts.length === 0">
      {{ $t('changeArticle.noArticlesAvailable') }}
    </p>

    <template v-else>
      <component
        :is="isMultiSelect ? 'v-row' : 'app-radio'"
        :key="pageStartIndex"
        v-bind="
          !isMultiSelect
            ? {
                value: newArticleNumbers[0],
                row: true,
                dense: true
              }
            : {}
        "
        class="selectable-articles__list"
      >
        <page-switch-column
          :hide="pages === 1"
          icon="$iconArrowLeft"
          :disabled="pageStartIndex === 0"
          @click="pageStartIndex = pageStartIndex - articlesPerPage"
        >
          <template v-if="pageStartIndex > 0">
            {{ mainSelectableProducts[pageStartIndex - 1].productCode }}
          </template>
        </page-switch-column>
        <v-col
          v-for="product in mainSelectableProducts.slice(
            pageStartIndex,
            pageStartIndex + articlesPerPage
          )"
          :key="product.articleNumber"
          class="selectable-articles__item"
        >
          <p class="font-weight-bold">
            {{ product.name || product.title }}
          </p>
          {{ product.productCode || '&nbsp;' }}
          <v-card
            :class="{
              'selectable-articles__card': true,
              'selectable-articles__card--shown': showArticleNumber === product.articleNumber,
              'selectable-articles__card--selected': newArticleNumbers.includes(
                product.articleNumber
              )
            }"
            outlined
            @click="showArticleNumber = product.articleNumber"
          >
            <div class="selectable-articles__image__wrapper">
              <product-image :article="product" height="8rem" max-width="90%" contain />
            </div>
            <icon-check
              class="selectable-articles__preview selectable-articles__preview--selected"
            />
            <div
              v-if="showArticleNumber !== product.articleNumber"
              class="selectable-articles__preview selectable-articles__preview--info"
            >
              <app-button text link :label="$t('changeArticle.showDetails')" />
            </div>
          </v-card>
          <div class="selectable-articles__actions">
            <requirements-tooltip
              v-if="isMultiSelect"
              :key="product.articleNumber"
              v-bind="getTooltipProps(product)"
            >
              <template v-slot:default="{ disabled }">
                <app-checkbox
                  v-model="newArticleNumbers"
                  :disabled="disabled"
                  v-bind="getCheckboxProps(product)"
                  @input="showArticleNumber = product.articleNumber"
                />
              </template>
            </requirements-tooltip>
            <v-radio
              v-else
              color="primary"
              :value="product.articleNumber"
              :label="
                newArticleNumbers[0] === product.articleNumber
                  ? $t('actions.selected')
                  : $t('actions.select')
              "
              @click="onSelectArticle(product.articleNumber)"
            />
          </div>
        </v-col>
        <v-spacer />

        <page-switch-column
          :hide="pages === 1"
          icon="$iconArrowRight"
          :disabled="pageStartIndex + articlesPerPage >= mainSelectableProducts.length"
          @click="pageStartIndex = pageStartIndex + articlesPerPage"
        >
          <template v-if="pageStartIndex + articlesPerPage < mainSelectableProducts.length">
            {{ mainSelectableProducts[pageStartIndex + articlesPerPage].productCode }}
          </template>
        </page-switch-column>
      </component>

      <v-row
        v-if="isMultiSelect && optionalSelectableProducts.length > 0"
        class="selectable-articles__list selectable-articles__list--optionals"
      >
        <v-col
          class="
            change-articles__column--main
            selectable-articles__item selectable-articles__item--none
          "
        >
          <div class="selectable-articles__actions">
            <requirements-tooltip
              v-for="product in optionalSelectableProducts"
              :key="product.articleNumber"
              v-bind="getTooltipProps(product)"
            >
              <template v-slot:default="{ disabled }">
                <app-checkbox
                  v-model="newArticleNumbers"
                  :disabled="disabled"
                  v-bind="getCheckboxProps(product)"
                  @input="showArticleNumber = product.articleNumber"
                />
              </template>
            </requirements-tooltip>
          </div>
        </v-col>
      </v-row>

      <app-radio
        v-if="!isMultiSelect && currentZone.isClearable"
        :value="newArticleNumbers[0]"
        class="selectable-articles__list"
        row
        dense
      >
        <v-col
          class="
            change-articles__column--main
            selectable-articles__item selectable-articles__item--none
          "
        >
          <div class="selectable-articles__actions" @click="onSelectArticle(valueNone)">
            <v-radio
              color="primary"
              :value="valueNone"
              :label="
                newArticleNumbers[0] === valueNone
                  ? $t('actions.selectedNothing')
                  : $t('actions.selectNothing')
              "
            />
          </div>
        </v-col>
      </app-radio>
    </template>

    <v-row class="change-articles__row">
      <v-col class="change-articles__column--main">
        <article-info
          v-if="selectedArticle"
          :article="selectedArticle"
          :show-change-hint="isDowngradeFromCV1500"
        />
      </v-col>
    </v-row>

    <div v-if="pages > 1" class="bottom-pagination">
      <app-button
        v-for="page in pages"
        :key="page"
        icon
        text
        :color="activePage === page ? 'primary' : 'grey-inactive'"
        @click="pageStartIndex = (page - 1) * articlesPerPage"
      >
        <v-icon small>mdi-circle</v-icon>
      </app-button>
    </div>
  </app-dialog>
</template>

<script>
import Area from '@/statics/area';
import Article from '@/statics/article';
import ArticleInfo from '@/components/steps/3/ChangeArticle/ArticleInfo';
import IconCheck from '@/assets/icons/icon-check.svg';
import PageSwitchColumn from '@/components/steps/3/ChangeArticle/PageSwitchColumn';
import ProductImage from '@/components/ProductImage';
import RequirementsTooltip from '@/components/RequirementsTooltip';
import Trolley from '@/statics/trolley';
import { ConfiguratorActionQuery } from '@/statics/dialogQueries';
import { checkSupport } from '@/helper/checkSupport';
import {
  getProductsWithSpecifiedMandatory,
  getMandatoryProducts,
  getProhibitedProducts
} from '@/helper/productDependencies';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ChangeArticleDialog',

  components: {
    ArticleInfo,
    IconCheck,
    PageSwitchColumn,
    ProductImage,
    RequirementsTooltip
  },

  props: {
    queryParam: {
      type: String,
      default: ConfiguratorActionQuery.CHANGE_ARTICLE
    },
    mainSelectableArticleNumbers: {
      type: Array,
      default: () => []
    },
    optionalSelectableArticleNumbers: {
      type: Array,
      default: () => []
    },
    currentZone: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    pageStartIndex: 0,
    showArticleNumber: '',
    mandatoryProducts: [],
    prohibitedProducts: [],
    newArticleNumbers: [],
    mainSelectableProducts: [],
    optionalSelectableProducts: [],
    loading: false,
    error: false
  }),

  computed: {
    ...mapState(['medicalSpeciality']),
    ...mapGetters(['selectedProcedureFocus']),
    articlesPerPage() {
      return this.$vuetify.breakpoint.smAndUp ? 3 : 1;
    },
    pages() {
      return Math.ceil(this.mainSelectableProducts.length / this.articlesPerPage);
    },
    activePage() {
      return Math.floor(this.pageStartIndex / this.articlesPerPage) + 1;
    },
    selectedArticle() {
      return this.selectableProducts.find(
        (article) => article.articleNumber === this.showArticleNumber
      );
    },
    isDowngradeFromCV1500() {
      return this.currentProducts.some(
        (article) => article.articleNumber === Article.CV_1500.articleNumber
      );
    },
    valueNone() {
      // v-radio doesn't support empty strings as value
      return 'none';
    },
    currentProducts() {
      return this.currentZone?.articles ?? [];
    },
    selectableProducts() {
      return [...this.mainSelectableProducts, ...this.optionalSelectableProducts];
    },
    isMultiSelect() {
      return this.currentZone?.isMultiSelect && this.selectableProducts.length > 1;
    },
    addedProducts() {
      return this.newArticleNumbers
        .filter(
          (newArticleNumber) =>
            newArticleNumber !== this.valueNone &&
            !this.currentProducts.some(
              (currentArticle) => currentArticle.articleNumber === newArticleNumber
            )
        )
        .map((newArticleNumber) =>
          this.selectableProducts.find((article) => article.articleNumber === newArticleNumber)
        );
    },
    removedProducts() {
      return this.currentProducts.filter(
        (currentArticle) => !this.newArticleNumbers.includes(currentArticle.articleNumber)
      );
    }
  },

  watch: {
    async value() {
      if (!this.value) {
        this.resetData();
        return;
      }

      this.loading = true;
      const currentArticleNumbers = this.currentProducts.map((article) => article.articleNumber);
      const fetchedArticles = await this.fetchSelectableArticles();

      if (this.optionalSelectableArticleNumbers.length > 0) {
        this.optionalSelectableProducts = this.filterSupportedArticles(
          fetchedArticles.splice(-this.optionalSelectableArticleNumbers.length)
        );
      }

      // set current article at start
      this.mainSelectableProducts = this.filterSupportedArticles(
        this.mapSelectableProducts(fetchedArticles)
      );
      this.mandatoryProducts = await this.fetchMandatoryProductsData();
      this.prohibitedProducts = await this.fetchProhibitedProductsData();

      this.newArticleNumbers =
        currentArticleNumbers.length > 0
          ? [...currentArticleNumbers]
          : this.isMultiSelect
          ? []
          : [this.valueNone];
      this.showArticleNumber = this.newArticleNumbers[0];
      this.loading = false;
    },

    articlesPerPage() {
      this.pageStartIndex = 0;
    },

    pageStartIndex() {
      this.showArticleNumber = this.mainSelectableProducts[this.pageStartIndex]?.articleNumber ?? 0;
    }
  },

  methods: {
    ...mapActions([
      'fetchArticles',
      'fetchArticlesInfo',
      'addProducts',
      'removeProducts',
      'switchProducts',
      'switchVideoProcessor'
    ]),

    async fetchSelectableArticles() {
      const articleNumbersFromProps = [
        ...this.mainSelectableArticleNumbers,
        ...this.optionalSelectableArticleNumbers
      ];

      if (articleNumbersFromProps.length > 0) {
        return await this.fetchArticlesInfo(articleNumbersFromProps);
      }

      return await this.fetchArticles(this.currentZone);
    },

    // fetch data from all mandatory products of each selectable, excluding trolleys
    async fetchMandatoryProductsData() {
      const mandatoryProductsArticleNumbers = [
        ...new Set(
          this.selectableProducts.reduce(
            (articleNumbers, product) => [
              ...articleNumbers,
              ...[...(product.mandatoryProducts ?? [])].filter(
                (articleNumber) => !Object.values(Trolley).includes(articleNumber)
              )
            ],
            []
          )
        )
      ];

      if (mandatoryProductsArticleNumbers.length === 0) {
        return [];
      }

      return await this.fetchArticlesInfo(mandatoryProductsArticleNumbers);
    },

    // fetch data from all excluding products of each selectable, excluding trolleys
    async fetchProhibitedProductsData() {
      const prohibitedProductsArticleNumbers = [
        ...new Set(
          this.selectableProducts.reduce(
            (articleNumbers, product) => [
              ...articleNumbers,
              ...[...(product.prohibitedProducts ?? [])].filter(
                (articleNumber) => !Object.values(Trolley).includes(articleNumber)
              )
            ],
            []
          )
        )
      ];

      if (prohibitedProductsArticleNumbers.length === 0) {
        return [];
      }

      return await this.fetchArticlesInfo(prohibitedProductsArticleNumbers);
    },

    filterSupportedArticles(articles) {
      return articles.filter(
        (article) =>
          checkSupport({
            selectedMedicalSpeciality: this.medicalSpeciality,
            selectedProcedureFocus: this.selectedProcedureFocus,
            supportedMedicalSpeciality: article.supportedMedicalSpeciality,
            supportedProcedureFocus: article.supportedProcedureFocus,
            articleNumber: article.articleNumber
          }).isSupported
      );
    },

    mapSelectableProducts(fetchedArticles) {
      return this.currentProducts.length === 0
        ? fetchedArticles
        : [
            ...this.currentProducts.filter(
              (item) => !this.optionalSelectableArticleNumbers.includes(item.articleNumber)
            ),
            ...fetchedArticles.filter(
              (item) =>
                !this.currentProducts.some(
                  (currentArticle) => currentArticle.articleNumber === item.articleNumber
                )
            )
          ];
    },

    getCheckboxProps(product) {
      return {
        class: 'selectable-articles__checkbox',
        color: 'primary',
        itemValue: product.articleNumber,
        label: this.buildLabel(product)
      };
    },

    getTooltipProps(product) {
      // list of mandatory products, of which at least one must be selected first, to select the passed one
      const productsToSelectFirst = getMandatoryProducts(
        product,
        this.mandatoryProducts,
        this.newArticleNumbers
      );
      if (productsToSelectFirst.length > 0) {
        return {
          headline: this.$t('changeArticle.hasRequirementsInfo'),
          requirements: productsToSelectFirst
        };
      }

      // the list of products, which must be unselected first, before the passed one can be unselected
      const productsToUnselectFirst = [
        // products which are mandatory for the current to be selectable
        ...getProductsWithSpecifiedMandatory(
          product,
          this.selectableProducts,
          this.newArticleNumbers
        ),
        // products which are prohibiting the selection of the current
        ...getProhibitedProducts(product, this.prohibitedProducts, this.newArticleNumbers)
      ];
      if (productsToUnselectFirst.length > 0) {
        return {
          headline: this.$t('changeArticle.isRequirementInfo'),
          requirements: productsToUnselectFirst
        };
      }

      return {};
    },

    buildLabel(product) {
      return `${
        this.newArticleNumbers.includes(product.articleNumber)
          ? this.$t('actions.selected')
          : this.$t('actions.select')
      } ${
        this.optionalSelectableArticleNumbers.includes(product.articleNumber) ? product.name : ''
      }`;
    },

    onSelectArticle(articleNumber) {
      this.newArticleNumbers = [articleNumber];
      this.showArticleNumber = articleNumber;
    },

    resetData() {
      this.optionalSelectableProducts = [];
      this.mainSelectableProducts = [];
      this.mandatoryProducts = [];
      this.newArticleNumbers = [];
      this.pageStartIndex = 0;
      this.loading = false;
      this.error = false;
    },
    async onSubmit() {
      if (this.removedProducts.length === 0 && this.addedProducts.length === 0) {
        this.close();
        return;
      }

      this.loading = true;

      const submitFunction = this.getSubmitFunction();
      const { error } = await submitFunction();

      this.loading = false;

      if (error) {
        return;
      }

      this.close();
    },

    getSubmitFunction() {
      if (this.addedProducts.length > 0 && this.removedProducts.length === 0) {
        return this.sendAddProducts;
      }

      if (this.addedProducts.length === 0 && this.removedProducts.length > 0) {
        return this.sendRemoveProducts;
      }

      if (this.addedProducts[0].isVideoProcessor && !this.isMultiSelect) {
        return this.sendSwitchVideoProcessor;
      }

      return this.sendSwitchProducts;
    },

    getSubmitDefaultPayload(article) {
      return {
        zone: article.zone?.index ?? this.currentZone.index,
        trolley: article.zone?.trolley ?? this.currentZone.trolley,
        area: article?.area ?? Area.DEFAULT
      };
    },

    async sendAddProducts() {
      return await this.addProducts(
        this.addedProducts.map((addedArticle) => ({
          ...this.getSubmitDefaultPayload(addedArticle),
          newArticleNumber: addedArticle.articleNumber,
          quantity: 1
        }))
      );
    },

    async sendRemoveProducts() {
      return await this.removeProducts(
        this.removedProducts.map((removedArticle) => ({
          ...this.getSubmitDefaultPayload(removedArticle),
          oldArticleNumber: removedArticle.articleNumber
        }))
      );
    },

    async sendSwitchVideoProcessor() {
      return await this.switchVideoProcessor({
        newVideoProcessor: this.addedProducts[0].articleNumber
      });
    },

    async sendSwitchProducts() {
      return await this.switchProducts([
        ...this.removedProducts.map((removedArticle) => ({
          ...this.getSubmitDefaultPayload(removedArticle),
          oldArticleNumber: removedArticle.articleNumber
        })),
        ...this.addedProducts.map((addedArticle) => ({
          ...this.getSubmitDefaultPayload(addedArticle),
          newArticleNumber: addedArticle.articleNumber,
          newQuantity: 1
        }))
      ]);
    },

    close() {
      this.$emit('input', false);
    }
  }
};
</script>

<style scoped lang="scss">
.change-articles__row {
  justify-content: center;
}

.change-articles__column--main {
  flex: 0 0 75%;
  max-width: 75%;
}

::v-deep .change-articles__column--side {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.selectable-articles__list {
  &.row,
  ::v-deep .v-input--radio-group__input {
    @extend .change-articles__row;
    align-items: flex-end;
    margin-top: 1rem;
  }
  .row:not(&:first-of-type) {
    margin-top: 2rem;
  }

  &.selectable-articles__list--optionals {
    .selectable-articles__checkbox,
    .v-input--radio-group__input {
      margin-right: 2rem;
    }
  }

  .selectable-articles__card {
    border: 1px solid var(--v-primary-base);
    padding: 1px;
    margin-bottom: 1rem;
    color: var(--v-primary-base);

    &.selectable-articles__card--shown {
      background-color: var(--v-blue-article-background-base);
    }

    &.selectable-articles__card--selected {
      border-width: $borderWidth;
      padding: 0;

      .selectable-articles__preview--selected {
        display: inline-block;
      }
    }
  }

  .selectable-articles__item {
    @extend .change-articles__column--main;
    @include body-font-style;
    padding: 0 1rem;

    color: var(--v-primary-base);

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: 0 0.25rem;

      &:not(.selectable-articles__item--none) {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  .selectable-articles__image__wrapper {
    padding: 2rem 0 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--v-primary-base);
  }

  .selectable-articles__preview {
    position: absolute;
  }

  .selectable-articles__preview--info {
    bottom: 0;
    width: 100%;
    text-align: center;
  }

  .selectable-articles__preview--selected {
    display: none;
    top: 0.25rem;
    right: 0.25rem;
    width: 1.5rem;
    color: var(--v-primary-base);
  }

  .selectable-articles__actions {
    @include body-2-font-style;
    padding: 0.5rem;
    display: block;
  }
}

.v-application.theme--light .bottom-pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;

  ::v-deep .v-btn.button--icon-only {
    width: auto;
    height: auto;
  }
}
</style>
