import Area from '@/statics/area';
import Zone from '@/statics/zone';
import { TROLLEY_ORDER } from '@/statics/trolley';
import { withSorting } from '@/helper/withSorting';

export const mapPreset = (preset, zones) => {
  return TROLLEY_ORDER.map((trolleyType) => ({
    type: trolleyType,
    articleZones: mapZones(preset[trolleyType].article ?? {}, zones),
    scopeZones: mapZones(preset[trolleyType].scope ?? [], zones)
  })).filter((trolleyConfig) =>
    trolleyConfig.articleZones.some((zone) => zone.articles.length > 0)
  );
};

const mapZones = (zones, zoneData) =>
  withSorting(
    Object.entries(zones).map(([zoneIndex, articlesInZone = {}]) => {
      const currentZoneData = zoneData.find((entry) => entry.index === zoneIndex);

      const zone = {
        index: currentZoneData.index,
        type: currentZoneData.type,
        isClearable: currentZoneData.isClearable,
        isMultiSelect:
          currentZoneData.selectionType === 'multiple' || currentZoneData.index === Zone.M6,
        isHidden: currentZoneData.index === Zone.M6,
        trolley: currentZoneData.trolley.articleNumber,
        supportedMedicalSpeciality: currentZoneData.supportedMedicalSpeciality,
        supportedProcedureFocus: currentZoneData.supportedProcedureFocus
      };

      return {
        ...zone,
        articles: withSorting(mapArticles(articlesInZone, zone), 'productCode')
      };
    }),
    'index'
  );

const mapArticles = (articles, zone) =>
  Object.entries(articles).reduce((articles, entry) => {
    const [area, articleConfig] = entry;

    // filter empty areas in zone
    if (!articleConfig) {
      return articles;
    }

    if (Array.isArray(articleConfig)) {
      // scope zone has multiple articles in same area
      articles.push(
        ...articleConfig.map((articleConfig) => ({
          ...articleConfig,
          area,
          zone
        }))
      );

      return articles;
    }

    articles.push({
      ...articleConfig,
      area,
      zone
    });

    return articles;
  }, []);

export const mapAccessories = (accessories, zones) =>
  Object.entries(accessories)
    .filter(([, accessory]) => accessory.default === undefined)
    .map(([zone, accessory]) => ({
      ...accessory,
      area: Area.DEFAULT,
      zone: zones.find((zoneData) => zoneData.index === zone)
    }));
