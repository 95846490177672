var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',_vm._g({attrs:{"loading":_vm.loading,"query-param":_vm.queryParam,"value":_vm.value},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('changeArticle.subline')))]),_c('h2',[_vm._v(_vm._s(_vm.$t('changeArticle.headline')))])]},proxy:true}])},_vm.$listeners),[_c('ValidationObserver',{ref:"scopeSelection",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();}}},[(_vm.selectableProducts.length === 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('changeArticle.noArticlesAvailable'))+" ")]):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredProductsWithSelectedQuantity,"no-data-text":_vm.$t('changeArticle.noScopesAvailable')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('app-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"selection"},_vm.selectedProducts.length > 0 ? on : null),[_c('b',{staticClass:"selection__counter"},[_vm._v(" "+_vm._s(_vm.$tc('changeArticle.scopesSelectedCounted', _vm.totalSelectedScopes))+" ")]),(_vm.selectedProducts.length > 0)?_c('icon-info',{staticClass:"selection__info-icon"}):_vm._e()],1)]}}])},[_c('b',[_vm._v(_vm._s(_vm.$t('changeArticle.currentlySelected')))]),_c('ul',{staticClass:"selection__list"},_vm._l((_vm.selectedProducts),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item.productCode)+" ("+_vm._s(_vm.$tc('shortPcsCounted', item.quantity))+") "),(item.technologyData['Scope type'])?[_vm._v(" ("+_vm._s(item.technologyData['Scope type'])+") ")]:_vm._e()],2)}),0)]),_c('scope-filters',{attrs:{"selectable-products":_vm.selectableProducts},model:{value:(_vm.filteredProducts),callback:function ($$v) {_vm.filteredProducts=$$v},expression:"filteredProducts"}})]},proxy:true},{key:"header",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header)+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"slim":"","name":_vm.$t('quantity'),"rules":("integer|min_value:" + (_vm.quantityLimits.min) + "|max_value:" + (_vm.quantityLimits.max)),"vid":("scope-" + (item.articleNumber))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:{
              quantity__wrapper: true,
              'quantity__wrapper--active': item.quantity > 0,
              'quantity__wrapper--error': errors.length > 0
            }},[_c('button',{attrs:{"disabled":item.quantity <= _vm.quantityLimits.min},on:{"click":function($event){return _vm.onUpdateQuantity(item.articleNumber, item.quantity - 1)}}},[_c('IconMinus')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quantityValueAsStrings[item.articleNumber]),expression:"quantityValueAsStrings[item.articleNumber]"}],key:item.articleNumber,staticClass:"quantity__input",attrs:{"id":("scope-" + (item.articleNumber)),"max":_vm.quantityLimits.max,"min":_vm.quantityLimits.min,"name":_vm.$t('quantity'),"placeholder":"0","type":"number"},domProps:{"value":(_vm.quantityValueAsStrings[item.articleNumber])},on:{"blur":function($event){return $event.target.reportValidity()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.quantityValueAsStrings, item.articleNumber, $event.target.value)}}}),_c('button',{attrs:{"disabled":item.quantity >= _vm.quantityLimits.max},on:{"click":function($event){return _vm.onUpdateQuantity(item.articleNumber, item.quantity + 1)}}},[_c('IconPlus')],1)])]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }