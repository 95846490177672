<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2 class="step__headline">{{ $t('request.headline') }}</h2>
        <app-alert v-if="error" color="error" class="error-alert">
          {{ $t('error.alert') }}
        </app-alert>
        <submit-contact-form class="contact__form" />
      </v-col>
    </v-row>
    <hr class="area__divider" />

    <h3>{{ $t('request.subline') }}</h3>
    <div class="summary__list">
      <summary-list :headline="$t('request.areaTrolley')" :list-items="selectedArticlesNames" />
    </div>
    <div v-if="selectedAccessoriesNames.length > 0" class="summary__list">
      <summary-list
        :headline="$t('request.areaAccessories')"
        :list-items="selectedAccessoriesNames"
      />
    </div>
  </div>
</template>

<script>
import AppAlert from '@/components/base/AppAlert';
import ArticleType from '@/statics/articleType';
import SubmitContactForm from '@/components/steps/5/SubmitContactForm.vue';
import SummaryList from '@/components/steps/5/SummaryList.vue';
import { filterArticlePropertiesInPreset } from '@/helper/filterArticlePropertiesInPreset';
import { mapGetters, mapState } from 'vuex';
import { withSorting } from '@/helper/withSorting';

export default {
  name: 'StepFiveRequest',

  components: { AppAlert, SummaryList, SubmitContactForm },

  props: {
    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(['preset']),
    ...mapGetters(['selectedAccessories']),

    selectedAccessoriesNames() {
      return withSorting(this.selectedAccessories.map((item) => item.title));
    },

    selectedArticlesNames() {
      return withSorting(
        filterArticlePropertiesInPreset(['name', 'title'], this.preset, this.buildItem)
      );
    }
  },

  methods: {
    buildItem(article) {
      if (article.type === ArticleType.SCOPE) {
        return `${article.productCategory} ${article.name ?? article.title} (${this.$tc(
          'shortPcsCounted',
          article.quantity
        )})`;
      }

      return article.name ?? article.title;
    }
  }
};
</script>

<style scoped>
.contact__form {
  margin-top: 3rem;
}

.area__divider {
  margin: 2rem 0 3rem 0;
}

.summary__list {
  margin: 1.5rem 0;
}

.error-alert {
  margin: 1.5rem 0;
}
</style>
