import { render, staticRenderFns } from "./ArticleInfo.vue?vue&type=template&id=7836f3ab&scoped=true"
import script from "./ArticleInfo.vue?vue&type=script&lang=js"
export * from "./ArticleInfo.vue?vue&type=script&lang=js"
import style0 from "./ArticleInfo.vue?vue&type=style&index=0&id=7836f3ab&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7836f3ab",
  null
  
)

export default component.exports