<template>
  <app-dialog
    :loading="loading"
    :query-param="queryParam"
    :value="value"
    v-on="$listeners"
    @submit="onSubmit"
  >
    <template v-slot:title>
      <span class="subtitle-1 font-weight-bold">{{ $t('changeArticle.subline') }}</span>
      <h2>{{ $t('changeArticle.headline') }}</h2>
    </template>

    <ValidationObserver ref="scopeSelection" tag="form" @submit.prevent>
      <p v-if="selectableProducts.length === 0">
        {{ $t('changeArticle.noArticlesAvailable') }}
      </p>

      <v-data-table
        v-else
        :headers="headers"
        :items="filteredProductsWithSelectedQuantity"
        :no-data-text="$t('changeArticle.noScopesAvailable')"
      >
        <template v-slot:top>
          <app-tooltip>
            <template #activator="{ on }">
              <div class="selection" v-on="selectedProducts.length > 0 ? on : null">
                <b class="selection__counter">
                  {{ $tc('changeArticle.scopesSelectedCounted', totalSelectedScopes) }}
                </b>
                <icon-info v-if="selectedProducts.length > 0" class="selection__info-icon" />
              </div>
            </template>

            <b>{{ $t('changeArticle.currentlySelected') }}</b>
            <ul class="selection__list">
              <li v-for="(item, index) in selectedProducts" :key="index">
                {{ item.productCode }} ({{ $tc('shortPcsCounted', item.quantity) }})
                <template v-if="item.technologyData['Scope type']">
                  ({{ item.technologyData['Scope type'] }})
                </template>
              </li>
            </ul>
          </app-tooltip>
          <scope-filters v-model="filteredProducts" :selectable-products="selectableProducts" />
        </template>
        <template v-slot:header="{ header }">
          {{ header }}
        </template>

        <template v-slot:item.quantity="{ item }">
          <ValidationProvider
            v-slot="{ errors }"
            slim
            :name="$t('quantity')"
            :rules="`integer|min_value:${quantityLimits.min}|max_value:${quantityLimits.max}`"
            :vid="`scope-${item.articleNumber}`"
          >
            <div
              :class="{
                quantity__wrapper: true,
                'quantity__wrapper--active': item.quantity > 0,
                'quantity__wrapper--error': errors.length > 0
              }"
            >
              <button
                :disabled="item.quantity <= quantityLimits.min"
                @click="onUpdateQuantity(item.articleNumber, item.quantity - 1)"
              >
                <IconMinus />
              </button>
              <input
                :id="`scope-${item.articleNumber}`"
                :key="item.articleNumber"
                v-model="quantityValueAsStrings[item.articleNumber]"
                :max="quantityLimits.max"
                :min="quantityLimits.min"
                :name="$t('quantity')"
                class="quantity__input"
                placeholder="0"
                type="number"
                @blur="$event.target.reportValidity()"
              />
              <button
                :disabled="item.quantity >= quantityLimits.max"
                @click="onUpdateQuantity(item.articleNumber, item.quantity + 1)"
              >
                <IconPlus />
              </button>
            </div>
          </ValidationProvider>
        </template>
      </v-data-table>
    </ValidationObserver>
  </app-dialog>
</template>

<script>
import AppTooltip from '@/components/base/AppTooltip';
import IconInfo from '@/assets/icons/icon-info.svg';
import IconMinus from '@/assets/icons/icon-minus.svg';
import IconPlus from '@/assets/icons/icon-plus.svg';
import ScopeFilters from '@/components/steps/3/ChangeArticle/ScopeFilters';
import Area from '@/statics/area';
import { ConfiguratorActionQuery } from '@/statics/dialogQueries';
import { mapActions } from 'vuex';
import { withSorting } from '@/helper/withSorting';

export default {
  name: 'ChangeScopeDialog',

  components: { AppTooltip, IconInfo, IconPlus, IconMinus, ScopeFilters },

  props: {
    currentZone: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    selectableProducts: [],
    filteredProducts: [],
    queryParam: ConfiguratorActionQuery.CHANGE_SCOPE,
    quantityValueAsStrings: {},
    loading: false,
    error: false
  }),

  computed: {
    quantityLimits() {
      return { min: 0, max: 999 };
    },
    headers() {
      return [
        {
          text: this.$t('quantity'),
          value: 'quantity'
        },
        {
          text: this.$t('changeArticle.productCode'),
          value: 'productCode'
        },
        ...Object.keys(this.selectableProducts[0]?.technologyData ?? {})
          .slice(1)
          .map((key) => ({
            text: key,
            value: `technologyData.${key}`
          }))
      ];
    },
    filteredProductsWithSelectedQuantity() {
      return this.filteredProducts.map((product) => ({
        ...product,
        quantity: Number(this.quantityValueAsStrings[product.articleNumber])
      }));
    },
    selectedProducts() {
      return Object.entries(this.quantityValueAsStrings)
        .filter(([, quantity]) => quantity > 0)
        .map(([articleNumber, quantity]) => ({
          ...this.selectableProducts.find((item) => item.articleNumber === articleNumber),
          quantity: Number(quantity)
        }));
    },
    hasChanges() {
      return Object.entries(this.quantityValueAsStrings).some(([articleNumber, quantity]) => {
        const currentArticle = this.currentZone?.articles.find(
          (article) => article.articleNumber === articleNumber
        );

        return (currentArticle ? currentArticle.quantity ?? 1 : 0) !== Number(quantity);
      });
    },
    totalSelectedScopes() {
      return Object.values(this.quantityValueAsStrings).reduce(
        (acc, amount) => acc + Number(amount),
        0
      );
    }
  },

  watch: {
    value: {
      async handler() {
        if (!this.value) {
          this.resetData();
          return;
        }

        this.loading = true;
        const fetchedScopes = await this.fetchScopes(this.currentZone);

        this.selectableProducts =
          this.currentZone.articles.length === 0
            ? fetchedScopes
            : [
                ...withSorting(
                  this.currentZone.articles.map((article) => ({
                    ...article,
                    productCode: article.title
                  })),
                  'productCode'
                ),
                ...withSorting(
                  fetchedScopes.filter(
                    (item) =>
                      !this.currentZone.articles.some(
                        (currentArticle) => currentArticle.articleNumber === item.articleNumber
                      )
                  ),
                  'productCode'
                )
              ];

        this.quantityValueAsStrings = this.selectableProducts.reduce((acc, selectableArticle) => {
          const currentArticle = this.currentZone.articles.find(
            (currentArticle) => currentArticle.articleNumber === selectableArticle.articleNumber
          );

          acc[selectableArticle.articleNumber] = currentArticle
            ? String(currentArticle.quantity ?? 1)
            : 0;

          return acc;
        }, {});

        this.loading = false;
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(['fetchScopes', 'updateProductQuantity']),

    onUpdateQuantity(articleNumber, newQuantity) {
      this.quantityValueAsStrings[articleNumber] = String(newQuantity);
    },

    async sendUpdateQuantity() {
      const removedProducts = this.currentZone.articles
        .filter(
          (currentProduct) =>
            !this.selectedProducts.some(
              (selectedProduct) => selectedProduct.articleNumber === currentProduct.articleNumber
            )
        )
        .map((product) => ({ ...product, quantity: 0 }));

      return await this.updateProductQuantity(
        [...this.selectedProducts, ...removedProducts].map((updatedArticle) => ({
          zone: updatedArticle.zone?.index ?? this.currentZone.index,
          trolley: updatedArticle.zone?.trolley ?? this.currentZone.trolley,
          area: updatedArticle?.area ?? Area.DEFAULT,
          quantity: Math.min(
            Math.max(Math.round(updatedArticle.quantity), this.quantityLimits.min),
            this.quantityLimits.max
          ),
          articleNumber: updatedArticle.articleNumber
        }))
      );
    },

    async onSubmit() {
      if (!(await this.$refs.scopeSelection.validate())) {
        return;
      }

      if (!this.hasChanges) {
        this.close();
        return;
      }

      this.loading = true;

      const { error } = await this.sendUpdateQuantity();

      this.loading = false;

      if (error) {
        return;
      }

      this.close();
    },

    resetData() {
      this.selectableProducts = [];
      this.loading = false;
      this.error = false;
    },

    close() {
      this.$emit('input', false);
    }
  }
};
</script>

<style scoped lang="scss">
.selectable-scopes__divider {
  margin: 0.5rem 0;
}

.theme--light.v-data-table ::v-deep {
  // table
  > .v-data-table__wrapper > table {
    @include body-font-style;

    // table row
    tr {
      &.v-data-table__empty-wrapper {
        font-family: $fontFamilyBold;
        color: var(--v-primary-base);
        text-align: left;
      }

      > td:nth-child(2) {
        white-space: nowrap;
      }
    }

    // table and header cells
    > tbody > tr > td,
    > thead th {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      padding: 1rem;
    }

    > thead th {
      vertical-align: bottom;
      padding-bottom: 0;

      > span {
        display: block;
      }
    }

    td .v-input--checkbox {
      display: block;
    }
  }

  .v-data-table-header-mobile__select {
    display: none;
  }
}

.selection {
  display: inline-flex;
}

.selection__counter {
  margin-right: 0.5rem;
}

.selection__info-icon {
  display: inline-block;
  height: map-deep-get($headings, 'body-1', 'size');
  margin-top: $fontVerticalOffsetBody1;
}

.selection__list {
  margin-top: 0.5rem;
}

.quantity {
  &__wrapper {
    display: flex;
    margin: 1px;
    border: 1px solid var(--v-primary-base);
    max-width: fit-content;

    &--active {
      margin: 0;
      border-width: 2px;
    }

    &--error {
      border-width: 2px;
      border-color: var(--v-error-base);
    }

    button {
      padding: 4px 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--v-primary-base);

      &[disabled] {
        color: var(--v-grey-disabled-base);
        cursor: not-allowed;
      }

      svg {
        width: 0.75rem;
      }
    }
  }

  &__input {
    width: 50px;
    flex: 1;
    padding-top: 6px;
    padding-bottom: 2px;
    outline: none;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
</style>
