import { flattenArray } from '@/helper/flattenArray';

/**
 * filters property values of preset articles and scopes
 * only the first match in article is added to the returned list
 * @param properties {Array} - list of article properties to look for, first match gets returned
 * @param preset {Object} - current preset configuration
 * @param buildFn {Function} - customize generated return value for single article
 * @returns {Array} - list of first matching property value of the preset articles
 */
export const filterArticlePropertiesInPreset = (
  properties,
  preset,
  buildFn = getFirstMatchingPropertyValue
) =>
  flattenArray(
    Object.values(preset).map((trolleyData) =>
      Object.values({
        ...(trolleyData?.article ?? {}),
        ...(trolleyData?.scope ?? {})
      }).map((zoneData) =>
        (Object.values(zoneData) || []).map((articlesInZone) => {
          if (!articlesInZone) {
            return;
          }

          const articlesInZoneArray = Array.isArray(articlesInZone)
            ? articlesInZone
            : [articlesInZone];

          return articlesInZoneArray.map((article) => buildFn(article, properties));
        })
      )
    )
  ).filter((article) => !!article);

const getFirstMatchingPropertyValue = (object, properties) =>
  object[properties.find((property) => object[property])];
