import { render, staticRenderFns } from "./StepOneGettingStarted.vue?vue&type=template&id=57cda384&scoped=true"
import script from "./StepOneGettingStarted.vue?vue&type=script&lang=js"
export * from "./StepOneGettingStarted.vue?vue&type=script&lang=js"
import style0 from "./StepOneGettingStarted.vue?vue&type=style&index=0&id=57cda384&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57cda384",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VImg,VRow})
