<template>
  <div>
    <span class="text--text font-weight-bold">{{ headline }}:</span>
    <ul class="summary__list">
      <li v-for="(item, index) in listItems" :key="index" class="summary__item">
        <span class="summary__index font-weight-bold">
          {{ index + 1 }}
        </span>
        {{ item }}
        <hr
          :class="{ summary__divider: true, 'summary__divider--hidden': isLastInColumn(index) }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SummaryList',

  props: {
    headline: { type: String, default: '' },
    listItems: { type: Array, required: true }
  },

  methods: {
    isLastInColumn(index) {
      if (this.$vuetify.breakpoint.xs) {
        return index === this.listItems.length - 1;
      }

      return (
        index === this.listItems.length - 1 || index === Math.ceil(this.listItems.length / 2) - 1
      );
    }
  }
};
</script>

<style scoped lang="scss">
.summary__list {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  list-style-type: none;
  padding: 0;
  color: var(--v-text-base);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    column-count: 2;
    column-gap: 5rem;
  }
}

.summary__item {
  break-inside: avoid;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.summary__index {
  display: inline-block;
  min-width: 1.5rem;
}

.accessories__area {
  margin-top: 2rem;
}

.accessories__list {
  margin-top: 0.5rem;
}

body .theme--light.v-application hr.summary__divider {
  margin-top: 0.5rem;
  border-bottom-width: 0;

  &--hidden {
    border-color: transparent;
  }
}
</style>
