<template>
  <change-article-dialog
    :current-zone="currentZone"
    :main-selectable-article-numbers="esgProducts"
    :optional-selectable-article-numbers="optionalProducts"
    :query-param="queryParam"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
const M6_ARTICLES = [Article.ESG_150, Article.ESG_300, Article.APU_300];
const M6_OPTIONAL = [Article.OFP_3, Article.OFP_3_PLUS];

import Article from '@/statics/article';
import ChangeArticleDialog from '@/components/steps/3/ChangeArticle/ChangeArticleDialog';
import Trolley from '@/statics/trolley';
import Zone from '@/statics/zone';
import { ConfiguratorActionQuery } from '@/statics/dialogQueries';
import { findArticleInMappedPreset } from '@/helper/findArticleInMappedPreset';
import { mapGetters } from 'vuex';

export default {
  name: 'ChangeEsgDialog',

  components: { ChangeArticleDialog },

  data: () => ({
    queryParam: ConfiguratorActionQuery.CHANGE_ESG
  }),

  computed: {
    ...mapGetters(['mappedPreset']),
    currentZone() {
      return {
        ...this.mappedPreset
          .find((trolley) => trolley.type === Trolley.MAIN)
          .articleZones.find((zone) => zone.index === Zone.M6),
        articles: [...this.currentProducts]
      };
    },
    currentProducts() {
      return [...this.esgProducts, ...this.optionalProducts]
        .map((articleNumber) => findArticleInMappedPreset(articleNumber, this.mappedPreset))
        .filter((articleNumber) => !!articleNumber);
    },
    esgProducts() {
      return M6_ARTICLES.map((article) => article.articleNumber);
    },
    optionalProducts() {
      return M6_OPTIONAL.map((article) => article.articleNumber);
    }
  }
};
</script>
