import i18n from '@/plugins/i18n';

export const withSorting = (items, nestedProperty) => {
  const selectedLocale = i18n.locale;

  if (!nestedProperty) {
    return items.sort((a, b) => a.localeCompare(b, selectedLocale));
  }

  return items.sort((a, b) =>
    [a, b].every(
      (comparedItem) => typeof comparedItem === 'object' && nestedProperty in comparedItem
    )
      ? a[nestedProperty].localeCompare(b[nestedProperty], selectedLocale)
      : false
  );
};
