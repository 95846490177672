<template>
  <v-row>
    <v-col cols="12">
      <h2 class="step__headline">{{ $t('procedures.headline') }}</h2>

      <div class="step__area dense">
        <v-row align="center" justify="space-between">
          <v-col cols="12" sm="6">
            <h3>{{ $t('procedures.procedureRequest') }}</h3>
          </v-col>
          <v-col cols="12" sm="auto" class="procedures__actions">
            <v-row justify="end">
              <v-col cols="auto">
                <app-button
                  :label="$t('procedures.selectAll')"
                  outlined
                  @click="selectAllFilteredProcedures"
                />
              </v-col>
              <v-col cols="auto">
                <app-button
                  :label="$t('procedures.resetSelection')"
                  outlined
                  @click="resetSelection"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <p v-if="!formIsValid" class="step__error font-weight-bold">
          {{ $t('procedures.errorNoProcedureSelected') }}
        </p>
        <div
          v-for="(_, procedureType) in filteredProcedures"
          :key="procedureType"
          class="procedure__group"
        >
          <p class="procedure__title body-2">
            {{ $t(`procedures.procedureTypes.${procedureType}`) }}
          </p>
          <hr />
          <v-row class="procedure__list">
            <v-col
              v-for="item in filteredProcedures[procedureType]"
              :key="item.id"
              cols="12"
              sm="3"
            >
              <requirements-tooltip
                v-if="getRequiringProcedures(item).length > 0"
                :headline="$t('procedures.isRequirementInfo')"
                :requirements="getRequiringProcedures(item)"
              >
                <app-checkbox
                  v-model="selectedProcedures"
                  :label="item.name"
                  :item-value="item.id"
                  disabled
                />
              </requirements-tooltip>

              <requirements-tooltip
                v-else-if="getRequiredProcedures(item).length > 0"
                :headline="$t('procedures.hasRequirementsInfo')"
                :requirements="getRequiredProcedures(item)"
              >
                <app-checkbox
                  v-model="selectedProcedures"
                  :label="item.name"
                  :item-value="item.id"
                  disabled
                />
              </requirements-tooltip>

              <app-checkbox
                v-else
                v-model="selectedProcedures"
                :rules="{ required: { allowFalse: false } }"
                :label="item.name"
                :item-value="item.id"
                :validation-mode="formIsValid ? 'passive' : 'eager'"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="step__area">
        <h3>{{ $t('procedures.rangeRequest') }}</h3>
        <range-slider v-model="selectedRange" class="range__slider" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import RangeSlider from '@/components/steps/2/RangeSlider';
import RequirementsTooltip from '@/components/RequirementsTooltip';
import { getFlattenedProceduresList } from '@/helper/procedures';
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'StepTwoSpecification',

  components: { RangeSlider, RequirementsTooltip },

  computed: {
    ...mapState(['procedures', 'range', 'selectedProceduresIds', 'formIsValid']),
    ...mapGetters(['filteredProcedures']),
    selectedRange: {
      get() {
        return this.range;
      },
      set(value) {
        this.setRange(value);
      }
    },
    selectedProcedures: {
      get() {
        return this.selectedProceduresIds;
      },
      set(value) {
        this.setSelectedProceduresIds(value);
      }
    },
    allProcedures() {
      return getFlattenedProceduresList(this.procedures);
    }
  },

  created() {
    this.updateSelectedProcedures();
  },

  methods: {
    ...mapMutations(['setRange', 'setSelectedProceduresIds']),

    // list of procedures which require the disabled one and must be unselected to enable the current
    getRequiringProcedures(item) {
      return !this.selectedProcedures.includes(item.id)
        ? []
        : this.allProcedures
            .filter(
              (procedure) =>
                this.selectedProcedures.includes(procedure.id) &&
                procedure.requiredProcedures.includes(item.id)
            )
            .map((procedure) => procedure.name);
    },

    // list of procedures which are requirements for the item, one must be selected to enable the current
    getRequiredProcedures(item) {
      return item.requiredProcedures.some((id) => this.selectedProcedures.includes(id))
        ? []
        : item.requiredProcedures.map(
            (requiredProcedure) =>
              this.allProcedures.find((procedure) => procedure.id === requiredProcedure).name
          );
    },

    updateSelectedProcedures() {
      if (this.selectedProceduresIds.length === 0) {
        return;
      }

      const allPossibleProceduresIds = Object.values(this.filteredProcedures).reduce(
        (listedIds, proceduresByType) => {
          listedIds.push(...proceduresByType.map((procedure) => procedure.id));
          return listedIds;
        },
        []
      );

      const selectedAndPossibleProcedures = this.selectedProceduresIds.filter((selectedProcedure) =>
        allPossibleProceduresIds.some((procedureId) => {
          return procedureId === selectedProcedure;
        })
      );
      this.setSelectedProceduresIds(selectedAndPossibleProcedures);
    },

    selectAllFilteredProcedures() {
      this.selectedProcedures = Object.values(this.filteredProcedures)
        .flat()
        .map((procedure) => procedure.id);
    },

    resetSelection() {
      this.setSelectedProceduresIds([]);
    }
  }
};
</script>

<style scoped lang="scss">
.step {
  &__error {
    margin-top: 1rem;
    color: var(--v-error-base);
  }
}

.procedures {
  &__actions {
    flex-grow: 1;
  }
}

.procedure {
  &__title {
    font-family: HelveticaNeueBold, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  }

  &__group {
    margin-top: 2.5rem;
  }

  &__list {
    margin-top: 1rem;
  }
}

.range {
  &__slider {
    margin-top: 1rem;
  }
}
</style>
