import Vue from 'vue';

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';

import {
  email,
  integer,
  numeric,
  alpha_spaces,
  required,
  min_value,
  max_value
} from 'vee-validate/dist/rules';

const rules = { email, integer, numeric, alpha_spaces, required, min_value, max_value };
Object.keys(rules).forEach((key) => extend(key, rules[key]));

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
